import React from 'react';
import {graphql, StaticQuery} from 'gatsby';
import Button from '../../components/Button';
import BackgroundImage from 'gatsby-background-image';

const ComingSoon = (props) => {
  const imageData = props.data.file.childImageSharp.fluid;
  return (
    <div id="section-contact" className="d-flex flex-column align-items-center section-coming-soon w-100">

      <BackgroundImage
        Tag="section"
        loading="eager"
        className="section-coming-soon-poster w-100"
        fluid={imageData}
      />
      <div className="container d-flex flex-column align-items-center">
        <h3 className="mt-5 text-center">We Want To Hear From You</h3>
        <div className="d-flex mb-5">
          <Button className="mr-1" text="Our Survey" buttonClassName="button-primary" link="https://www.surveylegend.com/s/267a" />
          <Button className="ml-1" text="Contact Us" buttonClassName="button-secondary" link="mailto:contact@izifill.id" />
        </div>
      </div>
    </div>
  );
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "izifill_cover.jpg" }) {
          childImageSharp {
            # Specify a fluid image and fragment
            # The default maxWidth is 800 pixels
            fluid (quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
  `}
    render={(data) => <ComingSoon data={data} />}
  />
);
